
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Subscription",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Activate Plan",
        url: "account/subscription"
      },
      {
        name: "Deactivate Plan",
        url: "account/subscription"
      },
    ]);
    const plans = reactive([
      {
        id: 1,
        duration: "COVID-19 Plan",
        name: "Basic",
        price: 0,
      },
      {
        id: 2,
        duration: "Pay as you go",
        name: "Flexible",
        price: 5,
      },
      {
        id: 3,
        duration: "Unlimited",
        name: "Premium",
        price: 50,
      }
    ]);
    const name = ref("Account");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, plans };
  },
});
